import { GeoPoint } from '@firebase/firestore-types';
import { TeamMember } from './teamMember.model';

export interface Practice {
  uid: string;
  name: string;
  surname: string;
  email: string;
  mobileNumber: string;
  phoneNumber: string;
  address: string;
  city: string;
  country: string;
  googlePlaceId: string;
  geoPoint: GeoPoint;
  positionUids: string[];
  requestUids: string[];
  userUids: string[];
  badges: BadgeType[];
  description: string;
  logoImageRef: string;
  insidePracticeImageRefs: string[];
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  youtubeProfileUrl?: string;
  googleRating?: number;
  numberOfGoogleRatings?: number;
  numberOfVets?: number;
  numberOfNurses?: number;
  accreditationsTypes?: AccreditationType[];
  teamMembers?: TeamMember[];
}

export enum BadgeType {
  verified = 'verified'
}

export enum AccreditationType {
  accreditation1 = 'Cat Friendly Clinic',
  accreditation2 = 'Fear Free Certified',
  accreditation3 = 'RCVS Accredited',
  accreditation4 = 'RCVS Awards',
  accreditation5 = 'RCVS Approved Graduate Development Practice',
  accreditation6 = 'RCVS Approved Training Practice',
  accreditation7 = 'BVA Good Veterinary Workplaces Voluntary Code Signatory',
  accreditation8 = 'Australian Veterinary Association Accredited Veterinary Hospital',
  accreditation9 = 'New Zealand Veterinary Association Best Practice accredited',
  accreditation10 = 'SAVA Member Practice'
}
