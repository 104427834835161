







































































































































































































































































































































































































































import Vue from 'vue';
import { CurrencyType, VetAnimalType } from '@/models/user.model';
import { VetSkillType } from '@/models/position.model';
import { AccreditationType, Practice } from '@/models/practice.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { db, storage } from '@/main';
import { Place } from '@/models/place.model';
import { WorkConditionsType } from '@/models/position.model';
import { v4 as uuidv4 } from 'uuid';
import router from '@/router';
import { TeamMember } from '@/models/teamMember.model';

export default Vue.extend({
  name: 'PracticeProfile',
  components: {
    Positions: () => import('@/views/Positions.vue'),
    PlaceSearch: () => import('@/components/PlaceSearch.vue')
  },
  data() {
    return {
      isBusy: false,
      router: router,
      storage: storage,
      selectedTab: 0,
      practice: {} as Practice,
      teamMembers: [] as TeamMember[],
      rules: {
        required: (value: string) => !!value || 'Required.',
        min: (value: string) => value.length >= 6 || 'Min 6 characters',
        email: (value: string) =>
          /.+@.+\..+/.test(value) || 'E-mail must be valid',
        mobile: (value: string) =>
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
            value
          ) || 'Number must be valid',
        year: (value: string) =>
          value?.length === 4 || 'Please enter a four digit year',
        animalPreferences: (value: string[]) =>
          value?.length > 0 || 'Please select at least one animal type',
        descriptionLength: (value: string) =>
          value?.length <= 500 || 'Max 500 characters'
      },
      isPracticeDetailsFormValid: false,
      isBrandFormValid: false,
      isTeamDetailsFormValid: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      practiceAddressText: '',
      vetAnimalTypes: Object.values(VetAnimalType),
      workConditionTypes: Object.values(WorkConditionsType),
      vetSkillTypes: Object.values(VetSkillType),
      currencyTypes: Object.values(CurrencyType),
      accreditationTypes: Object.values(AccreditationType),
      vetSkillType: VetSkillType,
      currency: CurrencyType.usd,
      uploadProgress: 0,
      logoImage: undefined,
      practiceImage: undefined,
      logoUrl: undefined,
      practiceImageUrls: [] as { ref: string; url: string }[],
      teamMemberImageUrls: [] as { ref: string; url: string }[],
      place: {} as Place,
      placeLoading: false,
      originalPractice: {} as Practice
    };
  },
  methods: {
    getData() {
      db.collection('practices')
        .doc(this.$store.state.currentUser.activePractice.uid)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.practice = doc.data() as Practice;
          this.originalPractice = { ...this.practice };
          this.getTeamMembers();
          this.place = {
            address: this.practice.address,
            city: this.practice.city,
            country: this.practice.country,
            googlePlaceId: this.practice.googlePlaceId,
            geoPoint: this.practice.geoPoint
          };
          if (this.practice.logoImageRef) {
            const ref = storage.ref(this.practice.logoImageRef);
            this.logoUrl = await ref.getDownloadURL();
          }
          if (this.practice.insidePracticeImageRefs) {
            this.practice.insidePracticeImageRefs?.forEach(async refValue => {
              const ref = storage.ref(refValue);
              this.practiceImageUrls.push({
                ref: refValue,
                url: await ref.getDownloadURL()
              });
            });
          }
        });
    },
    getTeamMembers() {
      db.collection('practices')
        .doc(this.practice.uid)
        .collection('teamMembers')
        .get()
        .then(snapshot => {
          this.teamMembers = snapshot.docs.map(doc => doc.data() as TeamMember);
          this.teamMembers.forEach(async teamMember => {
            Vue.set(
              this.teamMemberImageUrls,
              teamMember.uid,
              await storage.ref(teamMember.imageRef).getDownloadURL()
            );
          });
        });
    },
    updateData() {
      db.collection('practices')
        .doc(this.$store.state.currentUser.activePractice.uid)
        .set(this.practice, { merge: true })
        .then(() => {
          this.showSnackbar('Practice updated.', 'success');
          this.originalPractice = { ...this.practice };
        })
        .catch((error: Error) => {
          this.showSnackbar(error.message, 'error');
          console.error(error);
        });
    },
    launchLogoUpload() {
      (this.$refs.logoFileInput as any).$refs.input.click();
    },
    async onLogoUpload() {
      this.uploadProgress = 1;
      try {
        const ref = storage.ref(`practice/logos/${this.practice.uid}`);
        const task = ref.put((this.logoImage as unknown) as Blob);
        task.on('state_changed', (snapshot: any) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        });
        await task
          .then(async () => {
            this.logoUrl = await ref.getDownloadURL();
            this.uploadProgress = 0;
          })
          .catch((error: Error) => {
            console.error(error);
          });
        this.practice.logoImageRef = `practice/logos/${this.practice.uid}_500x500`;
        this.updateData();
        this.showSnackbar('Logo uploaded.', 'success');
      } catch (error) {
        this.uploadProgress = 0;
        console.error(error);
      }
    },
    launchPracticeImageUpload() {
      (this.$refs.practiceImageInput as any).$refs.input.click();
    },
    async onPracticeImageUpload() {
      this.uploadProgress = 1;
      try {
        const uuid = uuidv4();
        const ref = storage.ref(
          `practice/insideImages/${this.practice.uid}_${uuid}`
        );
        if (!this.practice.insidePracticeImageRefs) {
          this.practice.insidePracticeImageRefs = [];
        }
        this.practice.insidePracticeImageRefs.push(
          `practice/insideImages/${this.practice.uid}_${uuid}_750x750`
        );
        const task = ref.put((this.practiceImage as unknown) as Blob);
        task.on('state_changed', (snapshot: any) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        });
        await task
          .then(async () => {
            this.practiceImageUrls.push({
              ref: `practice/insideImages/${this.practice.uid}_${uuid}_750x750`,
              url: await ref.getDownloadURL()
            });
            this.uploadProgress = 0;
          })
          .catch((error: Error) => {
            this.uploadProgress = 0;
            console.error(error);
          });

        this.updateData();
        this.showSnackbar('Practice image uploaded.', 'success');
      } catch (error) {
        console.error(error);
      }
    },
    removeImage(obj: { ref: string; url: string }) {
      const refIndex = this.practice.insidePracticeImageRefs.indexOf(obj.ref);
      if (refIndex > -1) {
        this.practice.insidePracticeImageRefs.splice(refIndex, 1);
      }
      const urlIndex = this.practiceImageUrls.indexOf(obj);
      if (urlIndex > -1) {
        this.practiceImageUrls.splice(urlIndex, 1);
      }
      const resizedRefToDelete = storage.ref(obj.ref);
      resizedRefToDelete
        .delete()
        .then(() => {
          this.showSnackbar('Successfully removed image', 'success');
        })
        .catch(error => {
          this.showSnackbar(error, 'error');
        });
      const originalRef = obj.ref.replace('_750x750', '');
      const refToDelete = storage.ref(originalRef);
      refToDelete
        .delete()
        .then(() => {
          this.showSnackbar('Successfully removed image', 'success');
        })
        .catch(error => {
          this.showSnackbar(error, 'error');
        });

      this.updateData();
    },
    placeSelected(place: Place) {
      this.practice.googlePlaceId = place.googlePlaceId;
      this.practice.address = place.address;
      this.practice.city = place.city;
      this.practice.country = place.country;
      this.practice.geoPoint = place.geoPoint;
      if (place.rating) {
        this.practice.googleRating = place.rating;
        this.practice.numberOfGoogleRatings = place.numberOfRatings;
      }
    },
    onMobileNumberInput(formattedNumber, { number }) {
      this.practice.mobileNumber = number.international;
    },
    onPracticePhoneNumberInput(formattedNumber, { number }) {
      this.practice.phoneNumber = number.international;
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  created() {
    this.getData();
    window.scrollTo(0, 0);
  },
  mounted() {
    if (this.$route.query.activeTab) {
      this.selectedTab = Number(this.$route.query.activeTab);
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   if (
  //     JSON.stringify(this.originalPractice) !== JSON.stringify(this.practice)
  //   ) {
  //     this.updateData();
  //     setTimeout(() => {
  //       next();
  //     }, 1000);
  //   } else {
  //     next();
  //   }
  // }
});
